import { FC } from 'react';
// components
import PageLayout from 'src/components/PageLayout';
import PageNotFound from 'src/components/PageNotFound';

const ErrorPage: FC = () => (
  <PageLayout withStartProjectForm={false}>
    <PageNotFound />
  </PageLayout>
);

export default ErrorPage;
